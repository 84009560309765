import styled from 'styled-components';

export const NotificationLinkButton = styled('button')`
  background: none;
  border: none;
  color: blue;
  text-decoration: underline;
  cursor: pointer;
  font-size: inherit;
  padding: 0;
`;
