import * as React from 'react';
import { Link } from 'react-router-dom';
import {
  FortumLogo,
  LanguageWidget,
  breakpoints as ElementalBreakpoints,
  IconMenu,
  IconButton,
  colors,
  Heading,
} from '@fortum/elemental-ui';
import { Language } from '@configs/i18n';
import { UserInfo } from '@models/userInfo';
import { useMediaQuery } from 'react-responsive';
import { AuthWidget } from './AuthWidget';
import { AppSidebar } from './AppSidebar';
import {
  StyledHeader,
  RightComponentsContainer,
  LogoContainer,
  ContainerSidebar,
  FlexLogoContainer,
  NavSpacer,
  HeaderContainer,
} from './styles';

interface AppHeaderProps {
  text: string;
  currentLanguage: Language;
  onLanguageSelect: (language: string) => void;
  languages: Language[];
  userName: string;
  customerType: UserInfo['customerType'];
}

export const AppHeader: React.FC<AppHeaderProps> = ({
  text,
  currentLanguage,
  onLanguageSelect,
  languages,
  userName,
  customerType,
}) => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${ElementalBreakpoints.l - 1}px)`,
  });

  const [sidebarVisible, setSidebarVisible] = React.useState(false);

  const showSidebar = () => setSidebarVisible(true);
  const hideSidebar = () => setSidebarVisible(false);

  let logoSize: 's' | 'm' | 'l';
  if (isMobile) {
    logoSize = 's';
  } else {
    logoSize = 'm';
  }

  return (
    <>
      <StyledHeader>
        <FlexLogoContainer>
          <ContainerSidebar>
            <IconButton
              status="plain"
              icon={<IconMenu />}
              aria-label="Search names"
              onClick={showSidebar}
              color={colors.inkGrey}
            />
          </ContainerSidebar>
          <LogoContainer>
            <Link to="/">
              <FortumLogo size={logoSize} />
            </Link>
          </LogoContainer>
        </FlexLogoContainer>
        <HeaderContainer>
          <Heading level={5}>{text}</Heading>
        </HeaderContainer>
        <RightComponentsContainer>
          <LanguageWidget
            languages={languages}
            selected={currentLanguage}
            onSelect={onLanguageSelect}
          />
          <AuthWidget userName={userName} customerType={customerType} />
        </RightComponentsContainer>
      </StyledHeader>
      <NavSpacer />
      <AppSidebar visible={sidebarVisible} onHide={hideSidebar} text={text} />
    </>
  );
};
