import { logException } from '@configs/azureAppInsights/utils';
import { Namespace } from '@configs/i18n';
import jsPDF from 'jspdf';
import { useTranslation } from 'react-i18next';
import {
  addHeader,
  customerInfo,
  pages,
  addMultiDeliveryPoints,
} from '@utils/pdfUtils';
import html2canvas from 'html2canvas';
import { colors } from '@fortum/elemental-ui';

const INK_GREY_RGB = 51;

interface DeliveryPointInfo {
  id: string;
  deliveryPointName: string;
  deliveryPointAddress: string;
  deliveryPointNumber: string;
  customerNumber: string;
}

export interface MultiDeliveryPoints {
  id: string;
  companyName: string;
  children: DeliveryPointInfo[];
}

export const useForcecastPdfDownloader = () => {
  const { t } = useTranslation<Namespace>('glossary');

  const downloadAsPDF = async (
    hiddenChartRef: React.RefObject<HTMLDivElement>,
    filename: string,
    title: string,
    userName: string,
    deliveryPointsData: MultiDeliveryPoints[],
    isMobileScreen: boolean,
    loadingCallback?: (loading: boolean) => void,
  ) => {
    try {
      await import('@assets/fonts/FortumSansTT-Regular-normal');
      await import('../../assets/fonts/FortumSansTT-Bold-bold');

      // eslint-disable-next-line new-cap
      const pdf = new jsPDF({ orientation: 'landscape' });

      pdf.setFont('FortumSansTT-Regular', 'normal');
      pdf.setFontSize(12);
      pdf.setTextColor(INK_GREY_RGB);

      addHeader(pdf);
      customerInfo(pdf, t, userName, deliveryPointsData);
      pdf.setFont('FortumSansTT-Bold', 'bold');
      pdf.text(title, 10, 80);

      const pageWidth = pdf.internal.pageSize.width;
      const pageHeight = pdf.internal.pageSize.height;

      const chartElement = hiddenChartRef.current;
      if (!chartElement) {
        throw new Error('No hidden graph to generate image.');
      }

      const originalStyles = {
        position: chartElement.style.position,
        backgroundColor: chartElement.style.backgroundColor,
        bottom: chartElement.style.bottom,
      };

      chartElement.style.position = 'absolute';
      chartElement.style.backgroundColor = colors.snowWhite;
      chartElement.style.bottom = '0';

      const canvas = await html2canvas(chartElement, {
        scale: 2,
        useCORS: true,
        width: 1200,
        height: 550,
      });

      Object.assign(chartElement.style, originalStyles);

      const chartImage = canvas.toDataURL('image/png');

      const chartYPosition = 90;
      const chartHeight = pageHeight - chartYPosition;

      pdf.addImage(
        chartImage,
        'PNG',
        10,
        chartYPosition,
        pageWidth - 20,
        chartHeight,
      );

      const totalDeliveryPoints = deliveryPointsData.reduce(
        (acc, company) => acc + company.children.length,
        0,
      );

      if (totalDeliveryPoints > 1) {
        addMultiDeliveryPoints(pdf, deliveryPointsData, addHeader, t);
      }

      pages(pdf);

      const pdfBlob = pdf.output('blob');
      const url = URL.createObjectURL(pdfBlob);

      const link = document.createElement('a');
      link.href = url;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      loadingCallback?.(false);
    } catch (err) {
      logException(`Error while downloading as PDF. ${err}`);
      loadingCallback?.(false);
    }
  };

  return { downloadAsPDF };
};
