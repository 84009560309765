import { appendRequestParams, getCiamAuthorizeRequestParams } from '@api/utils';
import { useMemo, useState, useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { createStateParam } from '@utils/auth';
import { usePkceGenerator } from '@utils/hooks/usePkceGenerator';
import { Urls } from '@api/const';
import {
  LocalStorageKeys,
  SessionStorageKeys,
  OneTrustScriptProps,
} from '@commons/consts';
import TagManager from 'react-gtm-module';

import { fetchTokenByRefresh } from '@api/auth/auth';
import { logException } from '@configs/azureAppInsights/utils';
import { AppMainLayout } from './AppMainLayout';
import { GeneralError } from './pages';

const App = () => {
  const { pkce, error } = usePkceGenerator();
  const location = useLocation();
  const [searchParams, _setSearchParams] = useSearchParams();
  const [isRefreshFinalized, setIsRefreshFinalized] = useState(true);

  useEffect(() => {
    const isMobileParam = searchParams.get('isHeatMobileApp') === 'true';
    const blockTrackingParam = searchParams.get('blockTracking') === 'true';

    if (!sessionStorage.getItem(SessionStorageKeys.isMobile)) {
      sessionStorage.setItem(
        SessionStorageKeys.isMobile,
        isMobileParam.toString(),
      );
    }

    let blockTracking = false;

    if (!sessionStorage.getItem(SessionStorageKeys.blockTracking)) {
      sessionStorage.setItem(
        SessionStorageKeys.blockTracking,
        blockTrackingParam.toString(),
      );
      blockTracking = blockTrackingParam;
    } else {
      blockTracking =
        sessionStorage.getItem(SessionStorageKeys.blockTracking) === 'true';
    }

    if (!blockTracking) {
      const oneTrustScript = document.createElement('script');
      oneTrustScript.src = OneTrustScriptProps.src;
      oneTrustScript.type = OneTrustScriptProps.type;
      oneTrustScript.setAttribute(
        'data-document-language',
        OneTrustScriptProps.dataDocumentLanguage,
      );
      oneTrustScript.setAttribute('charset', OneTrustScriptProps.charset);
      oneTrustScript.setAttribute(
        'data-domain-script',
        OneTrustScriptProps.dataDomainScript,
      );

      const oneTrustSecondScript = document.createElement('script');
      oneTrustSecondScript.type = OneTrustScriptProps.type;
      oneTrustSecondScript.textContent = 'function OptanonWrapper() {}';

      const gtmScript = document.createElement('script');
      gtmScript.innerHTML = `
        (function(w,d,s,l,i){
          w[l]=w[l]||[];
          w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
          var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),
              dl=l!='dataLayer'?'&l='+l:'';
          j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;
          f.parentNode.insertBefore(j,f);
        })
        (window,document,'script','dataLayer','GTM-T9G7P8T');
      `;

      const { head } = document;
      head.insertBefore(gtmScript, head.firstChild);
      head.insertBefore(oneTrustScript, head.children[1]);
      head.insertBefore(oneTrustSecondScript, head.children[2]);
      head.insertBefore(gtmScript, head.children[3]);

      const tagManagerArgs = {
        gtmId: 'GTM-T9G7P8T',
      };

      TagManager.initialize(tagManagerArgs);
    }

    const refreshToken = localStorage.getItem(LocalStorageKeys.refreshToken);
    if (isMobileParam && !!refreshToken) {
      setIsRefreshFinalized(false);
      fetchTokenByRefresh(refreshToken)
        .then(response => {
          localStorage.setItem(LocalStorageKeys.idToken, response.id_token);
          localStorage.setItem(
            LocalStorageKeys.refreshToken,
            response.refresh_token,
          );
          sessionStorage.setItem(
            SessionStorageKeys.token,
            response.access_token,
          );
        })
        .catch(e => {
          logException(`Error while fetching token by refresh token. ${e}`);
          sessionStorage.removeItem(SessionStorageKeys.token);
        })
        .finally(() => {
          setIsRefreshFinalized(true);
        });
    }
  }, []);

  const url = useMemo(() => {
    if (!pkce) return undefined;
    const state = createStateParam(location.pathname);
    const isMobileValue = sessionStorage.getItem(SessionStorageKeys.isMobile);
    const isMobile = !!isMobileValue && isMobileValue === 'true';
    const params = getCiamAuthorizeRequestParams(
      pkce.challenge,
      state,
      isMobile,
    );

    sessionStorage.setItem(SessionStorageKeys.codeVerifier, pkce.verifier);
    sessionStorage.setItem(SessionStorageKeys.state, state);

    return appendRequestParams(Urls.ciamAuthorize, params);
  }, [pkce]);

  if (
    !!sessionStorage.getItem(SessionStorageKeys.token) &&
    isRefreshFinalized
  ) {
    return <AppMainLayout />;
  }

  if ((!url || error) && isRefreshFinalized) {
    return <GeneralError />;
  }

  if (isRefreshFinalized && !!url) {
    window.location.replace(url);
  }

  return null;
};

export default App;
