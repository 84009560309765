import { fetchTokenByRefresh } from '@api/auth/auth';
import { HttpError, isUnauthorizedError } from '@api/types';
import { LocalStorageKeys, SessionStorageKeys } from '@commons/consts';
import { logException } from '@configs/azureAppInsights/utils';
import { useCallback } from 'react';

export const usePageRefreshOnUnauthorizedError = () => {
  const refreshPage = useCallback((error: HttpError | Error) => {
    if (isUnauthorizedError(error)) {
      const isMobile =
        sessionStorage.getItem(SessionStorageKeys.isMobile) === 'true';
      if (isMobile) {
        const refreshToken =
          localStorage.getItem(LocalStorageKeys.refreshToken) ?? '';
        fetchTokenByRefresh(refreshToken)
          .then(response => {
            localStorage.setItem(LocalStorageKeys.idToken, response.id_token);
            localStorage.setItem(
              LocalStorageKeys.refreshToken,
              response.refresh_token,
            );
            sessionStorage.setItem(
              SessionStorageKeys.token,
              response.access_token,
            );
            window.location.reload();
          })
          .catch(e => {
            logException(`Error while fetching token by refresh token. ${e}`);
            sessionStorage.removeItem(SessionStorageKeys.token);
            window.location.reload();
          });
      } else {
        sessionStorage.removeItem(SessionStorageKeys.token);
        window.location.reload();
      }
    }
  }, []);

  return { refreshPage };
};
