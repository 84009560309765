const popItem = (key: string): string | null => {
  const item = sessionStorage.getItem(key);

  sessionStorage.removeItem(key);

  return item;
};

const popLocalItem = (key: string): string | null => {
  const item = localStorage.getItem(key);

  localStorage.removeItem(key);

  return item;
};

export const StorageUtils = {
  popItem,
  popLocalItem,
};
