import { ContentTypes, RequestHeaderNames, Urls } from '@api/const';
import {
  appendRequestParams,
  getAuthorizationHeaders,
  getCiamEndSessionRequestParams,
  getCiamTokenRequestParams,
  getCiamTokenByRefreshRequestBody,
  getCiamRevokeTokenRequestBody,
} from '@api/utils';
import { LocalStorageKeys, SessionStorageKeys } from '@commons/consts';
import { StorageUtils } from '@utils/storage';

export const fetchTokens = async (
  authCode: string,
  codeVerifier: string,
  isMobile: boolean,
) => {
  const headers = new Headers();
  headers.append(RequestHeaderNames.contentType, ContentTypes.urlEncoded);

  const params = getCiamTokenRequestParams(authCode, codeVerifier, isMobile);

  const urlWithParams = appendRequestParams(Urls.ciamToken, params);

  const response = await fetch(urlWithParams, {
    method: 'POST',
    mode: 'cors',
    headers,
  });

  if (response.ok) {
    return response.json();
  }

  throw Error(`${response.status} ${response.statusText}`);
};

export const fetchTokenByRefresh = async (refreshToken: string) => {
  const headers = new Headers();
  headers.append(RequestHeaderNames.contentType, ContentTypes.urlEncoded);

  const requestBody = getCiamTokenByRefreshRequestBody(refreshToken);

  const response = await fetch(Urls.ciamToken, {
    method: 'POST',
    mode: 'cors',
    headers,
    body: requestBody,
  });

  if (response.ok) {
    return response.json();
  }

  throw Error(`${response.status} ${response.statusText}`);
};

export const endForgerockSession = (token: string, isMobile: boolean) => {
  const headers = new Headers();
  headers.append(RequestHeaderNames.contentType, ContentTypes.urlEncoded);

  const requestParams = getCiamEndSessionRequestParams(token, isMobile);
  const urlWithParams = appendRequestParams(Urls.ciamEndSession, requestParams);

  return fetch(urlWithParams, {
    method: 'GET',
    mode: 'cors',
    headers,
  });
};

export const revokeToken = (token: string, isMobile: boolean) => {
  const requestBody = getCiamRevokeTokenRequestBody(token, isMobile);

  const headers = new Headers();
  headers.append(RequestHeaderNames.contentType, ContentTypes.json);

  return fetch(Urls.ciamRevokeToken, {
    method: 'POST',
    mode: 'cors',
    headers,
    body: JSON.stringify(requestBody),
  });
};

export const terminateApplicationSession = async () => {
  await fetch(Urls.appSessionTermination, {
    method: 'GET',
    mode: 'cors',
    headers: getAuthorizationHeaders(),
  });

  const isMobile =
    sessionStorage.getItem(SessionStorageKeys.isMobile) === 'true';

  const idToken = isMobile
    ? StorageUtils.popLocalItem(LocalStorageKeys.idToken)
    : StorageUtils.popItem(SessionStorageKeys.idToken);

  if (!idToken) {
    throw Error('ID token could not be found in the session storage');
  }

  const token = StorageUtils.popItem(SessionStorageKeys.token);
  if (!token) {
    throw Error('Token could not be found in the session storage');
  }

  await endForgerockSession(idToken, isMobile);
  await revokeToken(token, isMobile);
};
