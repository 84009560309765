import React from 'react';
import { subRoutes } from '@routers/routes';
import { ContentText } from '@fortum/elemental-ui';
import { useTabsWithRouter } from '@utils/hooks/useTabsWithRouter';
import { NotificationLinkButton } from './styles';

interface NotificationTextWithLinkProps {
  notificationText: string;
  notificationLinkText: string;
  notificationLinkUrl: string;
}

export const NotificationTextWithLink: React.FC<
  NotificationTextWithLinkProps
> = ({ notificationText, notificationLinkText, notificationLinkUrl }) => {
  const { onTabChange } = useTabsWithRouter();

  const isInternal =
    notificationLinkUrl === subRoutes.consumption ||
    notificationLinkUrl === subRoutes.invoices ||
    notificationLinkUrl === subRoutes.reports ||
    notificationLinkUrl === subRoutes.ownInformation ||
    notificationLinkUrl === subRoutes.consumptionSites ||
    notificationLinkUrl === subRoutes.contactUs;

  return isInternal ? (
    <ContentText>
      {`${notificationText} `}
      <NotificationLinkButton onClick={() => onTabChange(notificationLinkUrl)}>
        {notificationLinkText}
      </NotificationLinkButton>
    </ContentText>
  ) : (
    <ContentText>
      {`${notificationText} `}
      <a href={notificationLinkUrl} target="_blank" rel="noopener noreferrer">
        {notificationLinkText}
      </a>
    </ContentText>
  );
};
