import { breakpoints } from '@configs/breakpoints';
import {
  Box,
  px2rem,
  colors,
  ContentText,
  SidebarItem,
  IconCross,
  SidebarItemGroup,
} from '@fortum/elemental-ui';
import styled from 'styled-components';

export const StyledHeader = styled(Box)`
  height: ${px2rem(80)};
  max-height: ${px2rem(80)};
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding-left: ${px2rem(16)};
  padding-right: ${px2rem(40)};
  align-items: center;
  background-color: ${colors.snowWhite};
  border-bottom: 1px solid ${colors.fogGrey};
  box-sizing: border-box;

  @media screen and (max-width: ${breakpoints.mobile}) {
    padding-right: ${px2rem(16)};
    padding-top: ${px2rem(8)};
  }

  @media screen and (max-width: ${breakpoints.large}) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
  }
`;

export const LogoContainer = styled(Box)`
  @media screen and (max-width: ${breakpoints.large}) {
    padding-bottom: ${px2rem(8)};
  }
`;

export const HeaderSidebar = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const ContainerSidebar = styled(Box)`
  display: none;
  cursor: pointer;

  @media screen and (max-width: ${breakpoints.large}) {
    display: block;
  }
`;

export const CloseIconButton = styled(IconCross)`
  cursor: pointer;
`;

export const FlexLogoContainer = styled(Box)`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

export const HeaderTextMobile = styled(ContentText)`
  font-size: ${px2rem(18)} !important;
  padding-left: ${px2rem(16)};
`;

export const SidebarLink = styled(SidebarItem)`
  font-family: 'FortumSans', Arial, sans-serif;
`;

export const HeaderContainer = styled(Box)`
  margin-bottom: ${px2rem(4)};
`;

export const SidebarGroup = styled(SidebarItemGroup)`
  font-family: 'FortumSans', Arial, sans-serif;
`;

export const RightComponentsContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  @media screen and (max-width: ${breakpoints.mobile}) {
    padding-bottom: ${px2rem(8)};
  }
`;

export const AuthPopover = styled.div`
  display: flex;
  margin: ${px2rem(24)};
  flex-direction: column;
  row-gap: ${px2rem(32)};
  align-items: center;
`;

export const ActionText = styled(ContentText)`
  color: ${colors.cushyBlue};
  :hover {
    color: #2f507f;
  }
  cursor: pointer;
`;

export const ChangeServiceContainer = styled(Box)`
  display: flex;
  flex-flow: row nowrap;
  column-gap: ${px2rem(16)};
  align-self: flex-start;

  cursor: pointer;
`;

export const RowsDelimiter = styled.div`
  border: 0.5px solid ${colors.fogGrey};
  width: 100%;

  @media screen and (max-width: ${breakpoints.mobile}) {
    display: none;
  }
`;

export const NavSpacer = styled.div`
  @media screen and (max-width: ${breakpoints.large}) {
    margin-bottom: ${px2rem(78)};
  }

  @media screen and (max-width: ${breakpoints.mobile}) {
    margin-bottom: ${px2rem(0)};
  }
`;
