import React from 'react';
import {
  AuthWidget as ElementalAuthWidget,
  AuthWidgetProps as ElementalAuthWidgetProps,
  ContentText,
} from '@fortum/elemental-ui';
import { useTranslation } from 'react-i18next';
import { Namespace } from '@configs/i18n';
import { terminateApplicationSession } from '@api/auth/auth';
import { Urls } from '@api/const';
import IconApps from '@assets/Apps.svg';
import { UserInfo } from '@models/userInfo';
import { createSsoLoginPortalRedirectUrl } from '@api/utils';
import { logException } from '@configs/azureAppInsights/utils';
import { SessionStorageKeys } from '@commons/consts';
import { AuthPopover, ActionText, ChangeServiceContainer } from './styles';

type AuthWidgetProps = Omit<ElementalAuthWidgetProps, 'children'> & {
  customerType: UserInfo['customerType'];
};

export const AuthWidget: React.FC<AuthWidgetProps> = props => {
  const { t } = useTranslation<Namespace>('common');
  const { userName, customerType } = props;

  const logout = () => {
    terminateApplicationSession()
      .then(() => {
        localStorage.clear();
        window.location.assign(Urls.ciamSessionTermination);
      })
      .catch(_e => {
        logException(
          `Error while terminating application session during logout. ${_e}`,
        );
      });
  };

  const changeService = () => {
    terminateApplicationSession()
      .then(() => {
        localStorage.clear();
        window.location.assign(createSsoLoginPortalRedirectUrl(customerType));
      })
      .catch(_e => {
        logException(
          `Error while terminating application session during service change. ${_e}`,
        );
      });
  };

  return (
    <ElementalAuthWidget {...props}>
      <AuthPopover>
        {!!userName && <ContentText size="m">{userName}</ContentText>}

        {!(sessionStorage.getItem(SessionStorageKeys.isMobile) === 'true') && (
          <ChangeServiceContainer onClick={changeService}>
            <img src={IconApps} alt={t('action.changeService')} />
            <ActionText size={18}>{t('action.changeService')}</ActionText>
          </ChangeServiceContainer>
        )}

        <ActionText size={18} onClick={logout}>
          {t('action.logout')}
        </ActionText>
      </AuthPopover>
    </ElementalAuthWidget>
  );
};
