import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';
import Backend from 'i18next-locize-backend';
import { Language } from './types';
import 'moment/locale/fi';
import 'moment/locale/en-gb';
import 'moment/locale/sv';
import { isSupportedLanguage } from './utils';

export const supportedLanguages = ['en', 'fi', 'sv'] as const;
export const fallbackLng: Language = 'fi';

const savedLanguage = localStorage.getItem('selectedLanguage');
const initialLanguage =
  savedLanguage && isSupportedLanguage(savedLanguage)
    ? savedLanguage
    : fallbackLng;

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    debug: process.env.NODE_ENV === 'development',
    supportedLngs: supportedLanguages,
    lng: initialLanguage,
    fallbackLng,
    backend: {
      projectId: process.env.REACT_APP_LOCIZE_PROJECT_ID,
      referenceLng: fallbackLng,
    },
  });

moment.locale(initialLanguage === 'en' ? 'en-gb' : initialLanguage);

i18n.on('languageChanged', lang => {
  const selectedLanguage: Language = isSupportedLanguage(lang)
    ? lang
    : fallbackLng;

  moment.locale(selectedLanguage);

  localStorage.setItem('selectedLanguage', selectedLanguage);
});

export default i18n;
