import { SessionStorageKeys } from '@commons/consts';
import { AuthConfig } from '@configs/ciam';
import i18n, { isSupportedLanguage } from '@configs/i18n';
import { DeliveryPoint } from '@models/deliveryPoint';
import { UserInfo } from '@models/userInfo';
import { logEvent, logException } from '@configs/azureAppInsights/utils';
import { ContentTypes, RequestHeaderNames, Urls } from './const';
import { HttpError } from './types';

export const appendRequestParams = (
  url: string,
  params: Map<string, string | number>,
) => {
  if (params.size === 0) {
    return url;
  }

  let urlWithParams = `${url}?`;

  params.forEach((value, key) => {
    urlWithParams += `${key}=${value}&`;
  });

  return urlWithParams.substring(0, urlWithParams.length - 1);
};

export const getCiamAuthorizeRequestParams = (
  codeChallenge: string,
  state: string,
  isMobile: boolean,
): Map<string, string> =>
  new Map<string, string>([
    [
      'client_id',
      isMobile
        ? process.env.REACT_APP_FORGEROCK_MOBILE_CLIENT_ID ?? ''
        : process.env.REACT_APP_FORGEROCK_CLIENT_ID ?? '',
    ],
    ['redirect_uri', process.env.REACT_APP_AUTH_REDIRECT_URI ?? ''],
    ['scope', AuthConfig.scope],
    ['response_type', AuthConfig.responseType],
    ['code_challenge', codeChallenge],
    ['code_challenge_method', 'S256'],
    ['state', state],
    ['acr_values', AuthConfig.acrValues],
  ]);

export const getCiamTokenRequestParams = (
  authCode: string,
  codeVerifier: string,
  isMobile: boolean,
): Map<string, string> =>
  new Map<string, string>([
    ['grant_type', AuthConfig.grantType],
    ['code', authCode],
    [
      'client_id',
      isMobile
        ? process.env.REACT_APP_FORGEROCK_MOBILE_CLIENT_ID ?? ''
        : process.env.REACT_APP_FORGEROCK_CLIENT_ID ?? '',
    ],
    ['redirect_uri', process.env.REACT_APP_AUTH_REDIRECT_URI ?? ''],
    ['code_verifier', codeVerifier],
  ]);

export const getCiamTokenByRefreshRequestBody = (
  refreshToken: string,
): URLSearchParams =>
  new URLSearchParams({
    grant_type: AuthConfig.refreshGrantType,
    refresh_token: refreshToken,
    client_id: process.env.REACT_APP_FORGEROCK_MOBILE_CLIENT_ID ?? '',
  });

export const getCiamEndSessionRequestParams = (
  token: string,
  isMobile: boolean,
): Map<string, string> =>
  new Map<string, string>([
    ['id_token_hint', token],
    [
      'client_id',
      isMobile
        ? process.env.REACT_APP_FORGEROCK_MOBILE_CLIENT_ID ?? ''
        : process.env.REACT_APP_FORGEROCK_CLIENT_ID ?? '',
    ],
    ['post_logout_redirect_url', Urls.ciamSessionTermination],
  ]);

interface RevokeTokenBody {
  token: string;
  client_id: string;
  client_secret: string;
}

export const getCiamRevokeTokenRequestBody = (
  token: string,
  isMobile: boolean,
): RevokeTokenBody => {
  return {
    token,
    client_id: isMobile
      ? process.env.REACT_APP_FORGEROCK_MOBILE_CLIENT_ID ?? ''
      : process.env.REACT_APP_FORGEROCK_CLIENT_ID ?? '',
    client_secret: isMobile
      ? process.env.REACT_APP_FORGEROCK_MOBILE_CLIENT_SECRET ?? ''
      : process.env.REACT_APP_FORGEROCK_CLIENT_SECRET ?? '',
  };
};

export const getDeliveryPointDetailsRequestParams = (
  groupId: string,
  installationId: string,
  scope: DeliveryPoint['scope'] | undefined,
): Map<string, string> => {
  const requiredParams = new Map<string, string>([
    ['groupId', groupId],
    ['installationId', installationId],
  ]);

  if (scope) {
    requiredParams.set('scope', scope);
  }

  return requiredParams;
};

export const getEnclosurePdfRequestParams = (
  groupId: string,
  installationId: string,
  scope: DeliveryPoint['scope'] | undefined,
): Map<string, string> => {
  const requiredParams = new Map<string, string>([
    ['groupId', groupId],
    ['installationId', installationId],
  ]);

  if (scope) {
    requiredParams.set('scope', scope);
  }

  return requiredParams;
};

type HeaderName = string;
type HeaderValue = string;
export const getAuthorizationHeaders = (): [HeaderName, HeaderValue][] => {
  const token = sessionStorage.getItem(SessionStorageKeys.token);

  return [
    [
      RequestHeaderNames.apimSubscriptionKey,
      process.env.REACT_APP_APIM_SUBSCRIPTION_KEY ?? '',
    ],
    [RequestHeaderNames.authorization, `Bearer ${token}`],
  ];
};

interface GetDataProps<T extends {}> {
  url: string;
  responseMapping?: (jsonData: any) => T;
  errorMessage?: string;
}
export const getData = async <T extends {}>({
  url,
  responseMapping,
  errorMessage,
}: GetDataProps<T>): Promise<T> => {
  const response = await fetch(url, {
    mode: 'cors',
    headers: [
      ...getAuthorizationHeaders(),
      [RequestHeaderNames.contentType, ContentTypes.json],
    ],
  });

  if (response.ok) {
    try {
      const data = await response.json();
      logEvent(`GET DATA FROM ${url}`);
      return responseMapping ? responseMapping(data) : data;
    } catch (error) {
      logException(`Could not parse the data from ${url}. ${error}`);
      return Promise.reject(Error(`Could not parse the data. ${error}`));
    }
  }

  logException(
    `Error while fetching data from ${url}. Error: ${
      errorMessage ?? 'Could not fetch the data'
    }`,
  );
  return Promise.reject(
    new HttpError(
      response.status,
      response.statusText,
      errorMessage ?? 'Could not fetch the data',
    ),
  );
};

interface GetDataByPostProps<T extends {}> {
  url: string;
  body: string;
  responseMapping?: (jsonData: any) => T;
  errorMessage?: string;
}
export const getDataByPost = async <T extends {}>({
  url,
  body,
  responseMapping,
  errorMessage,
}: GetDataByPostProps<T>): Promise<T> => {
  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    headers: [
      ...getAuthorizationHeaders(),
      [RequestHeaderNames.contentType, ContentTypes.json],
    ],
    body,
  });

  if (response.ok) {
    try {
      const data = await response.json();
      logEvent(`GET DATA BY POST FROM ${url}`);
      return responseMapping ? responseMapping(data) : data;
    } catch (error) {
      logException(`Could not parse the data from ${url}. ${error}`);
      return Promise.reject(Error(`Could not parse the data. ${error}`));
    }
  }

  logException(
    `Error while fetching data by post from ${url}. Error: ${
      errorMessage ?? 'Could not fetch the data by post'
    }`,
  );
  return Promise.reject(
    new HttpError(
      response.status,
      response.statusText,
      errorMessage ?? 'Could not fetch the data by post',
    ),
  );
};

export const createSsoLoginPortalRedirectUrl = (
  customerType: UserInfo['customerType'],
) => {
  const customerTypeParameter = customerType === 'B2B' ? 'business' : 'private';
  const customerTypeParameterName = 'customer-login-open';

  const urlWithOptionalLanguage = isSupportedLanguage(i18n.language)
    ? `${Urls.ssoLoginPortal}/${i18n.language}?`
    : `${Urls.ssoLoginPortal}/?`;

  return `${urlWithOptionalLanguage}${customerTypeParameterName}=${customerTypeParameter}`;
};

export const optionallyAppendScopeParameter = (
  url: string,
  scope: string | null | undefined,
): string => {
  let urlWithOptionalParams = url;
  if (scope) {
    const requestParams = new Map<string, string | number>([['scope', scope]]);
    urlWithOptionalParams = appendRequestParams(url, requestParams);
  }

  return urlWithOptionalParams;
};
